@import "../colors";

.checkin-table-quick-filters {
    border: 1px solid $ant-gray;
    width: 50%;
    border-radius: $borderRadius;

    .ant-checkbox-group-item {
        max-width: 105px;
    }
}

.badge-serial-form-container {
    font-size: $fontSizeIcons;
}

.check-in-table-row-highlighted {
    background-color: $adpo-orange;
}

.check-in-overview-table {
    td {
        &.check-in-table-remark {
            height: 39px;
            width: 39px;
        }
    }

    .ant-table-cell {
        &.check-in-table-remark.check-in-table-remark {
            padding: 0 !important;
            svg {
                height: 38px;
                width: 38px;
            }
        }
    }
}

.check-in-delete-button {
    background: $adpo-red;
    border-color: $adpo-red;
    color: $adpo-white;
}

.scrollable-box {
    overflow-y: auto;
    max-height: 500px;
}
