// Constants
$space: 20px;
$padding: $space;
$margin: $space;
$borderRadius: 4px;
$fontSizeIcons: 21px;
$boxBorder: 1px solid $ant-gray;

// Classes
.full-page {
  height: 100vh;
  width: 100vw;
}

.border-radius {
  border-radius: $borderRadius;
}

.top-header {
  padding: 0;
  background: $adpo-white;
}

.logo {
  float: left;
  width: 120px;
  height: 38px;
  margin: 16px 24px 16px 0;
}

.fill-width {
  width: 100%;
}

.fill-height {
  height: 100%;
}

.fill-parent {
  @extend .fill-width;
  @extend .fill-height;
}

.no-padding {
  padding: 0 !important;
}

.padded {
  padding: $padding;
}

.padded-left {
  padding-left: $padding;
}

.padded-right {
  padding-right: $padding;
}

.padded-half {
  padding: $padding / 2;
}

.padded-half-left {
  padding-left: $padding / 2;
}

.margin {
  margin: $margin;
}

.margin-half {
  margin: $margin / 2;
}

.no-margin {
  margin: 0 !important;
}

.margin-right {
  margin-right: $margin;
}

.margin-left-auto {
  margin-left: auto;
}

.margin-left {
  margin-left: $margin;
}

.margin-half-left {
  margin-left: $margin / 2;
}

.margin-third-left {
  margin-left: $margin / 3;
}

.margin-half-right {
  margin-right: $margin / 2;
}

.margin-bottom {
  margin-bottom: $margin;
}

.margin-double-bottom {
  margin-bottom: $margin * 2;
}

.margin-half-bottom {
  margin-bottom: $margin / 2;
}

.margin-third-bottom {
  margin-bottom: $margin / 3;
}

.margin-half-top {
  margin-top: $margin / 2;
}

.margin-double-top {
  margin-top: $margin * 2;
}

.margin-top {
  margin-top: $margin;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text-middle {
  text-align: center;
}

.align-middle {
  vertical-align: sub;
}

.flex {
  display: flex;
}

.display-none {
  display: none;
}

.box-border {
  border: $boxBorder;
}

.block {
  display: block;
}

.inline-flex {
  display: inline-flex;
}

.inline-block {
  display: inline-block;
}

.inline-table {
  display: inline-table;
}

.wrap {
  flex-wrap: wrap;
}

.space-evenly {
  justify-content: space-evenly;
}

.space-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-align-center {
  align-content: baseline;
}

.flex-start {
  justify-content: start;
}

.align-items-stretch {
  align-items: stretch;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
}

.align-self-center {
  align-self: center;
}

.scroll {
  overflow: auto;
}

.width-30-percent {
  width: 30%;
}

.width-40-percent {
  width: 40%;
}

.width-50-percent {
  width: 50%;
}

.list-align {
  display:table-row;
  list-style-type:none;
  padding-inline-start: 0;
  position: relative;
}

.list-align-item {
  display: table-cell;
  vertical-align: middle;
}

.list-align b::after {
  display: inline-block;
}

.align-right {
  position: absolute;
  right: 25px;
}

.not-bold {
  font-weight: normal;
}

.bold {
  font-weight: bold;
}

.table-column-icons {
  text-align: right;

  .table-icon:first-child {
    padding-left: 0;
  }
}

.table-icon {
  font-size: 1.3em;
  padding-left: 5px;
  padding-right: 0;
}

.table-icon-baffles {
  color: $icon-blue;
}

.table-icon-manual-work {
  color: $adpo-green;
}

.icon-tooltip-table {
  &::after {
    left: 95% !important;
  }
}

.icon-info-tooltip {
  display: inline-block;
  margin-left: 5px;
  margin-top: 4px;
  cursor: help;
}

.text-right {
  text-align: right;
}

.menu-right-item {
  margin-left: auto;
  margin-right: 18px !important;
}
