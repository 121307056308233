@import "src/theme/scss/colors";
@import "src/theme/scss/layout";

.tabpane {
  color: $text-primary;

  .slot-tag {
    border-radius: 8px;

    &.padded {
      padding: 4px;
    }
  }
}

.cleaningtabs {
  .ant-tabs-nav-wrap {
    margin-bottom: 0;
  }

  .ant-tabs-ink-bar {
    height: 100%;
    border: 2px solid $primary;
    background-color: transparent;
    bottom: 0;
  }
}
