@import "src/theme/scss/colors";
@import "src/theme/scss/layout";

.cleaningmodal {
  .ant-modal-content {
    background-color: $adpo-gray;
  }

  .ant-modal-footer {
    @extend .padded;
  }

  .ant-modal-body {
    @extend .padded;
  }
}
