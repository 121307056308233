@import "colors";

$font-small: 12px;
$font-normal: 14px;
$font-large: 18px;

$icon-normal: 20px;
$icon-large: 30px;
$icon-extra-large: 50px;

.text-primary {
  color: $text-primary;
}

.text-secondary {
  color: $text-secondary;
}

.text-red {
  color: $adpo-red;
}

.text-disabled {
  color: $adpo-disabled;
}

.text-small {
  font-size: $font-small;
}

.text-normal {
  font-size: $font-normal;
}

.text-large {
  font-size: $font-large;
}

.icon-normal {
  font-size: $icon-normal;
}

.icon-large {
  font-size: $icon-large;
}

.icon-extra-large {
  font-size: $icon-extra-large;
}

.icon-filtered {
  color: $primary !important;
}

.text-success {
  color: $success-color;
}

.text-error {
  color: $error-color;
}

.truncated-text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; /* forces text to single line */
}
