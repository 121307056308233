// ADPO colors
$adpo-yellow: #f5cc00;
$adpo-white: white;
$adpo-orange: #f6edc5;
$adpo-blue:  #003366;
$adpo-gray: #f5f5f5;
$adpo-disabled: #8c8c8c;
$adpo-green: #A0D911;
$adpo-red: #f5222d;

$adpo-light-gray: lighten($adpo-gray, 30%);
$adpo-dark-gray: darken($adpo-gray, 30%);
$adpo-light-green: lighten($adpo-green, 30%);
$adpo-light-yellow: lighten($adpo-yellow, 30%);

// Ant colors
$ant-gray: #d9d9d9;

// Font colors
$primary: $adpo-yellow;
$text-primary:  rgba(0, 0, 0, 0.65);
$text-secondary: rgba(0, 0, 0, 0.45);

// General
$success-color: #52c41a; // success state color
$warning-color: #FA8C16; // success state color
$error-color: $adpo-red; // error state color
$icon-blue:  #4190f7;

.text-warning {
  color: $warning-color;
}

.text-info {
  color: $icon-blue;
}

.green {
  background: $adpo-green;
}

.yellow {
  background: $adpo-yellow;
}

.gray {
  background: $adpo-gray;
}

.light-green {
  background: $adpo-light-green;
}

.light-yellow {
  background: $adpo-light-yellow;
}
