// Utility css class to highlight the element you are styling
// TODO: remove when layout is done
.fluo {
  background-color: #52c41a;
}

.div-inline {
  display: inline;
}

.stripe-through {
  text-decoration: line-through;
}
