textarea {
  &.readonly {
    &:hover {
      outline: none;
      box-shadow: none;
      border: 1px solid #d9d9d9;
    }

    &:focus {
      outline: none;
      box-shadow: none;
      border: 1px solid #d9d9d9;
    }

    &:active {
      outline: none;
      box-shadow: none;
      border: 1px solid #d9d9d9;
    }
  }
}
