@import "fonts";
@import "colors";
@import "layout";

$ant-header-height: 64px;

.anticon {
  // @extend .icon-normal
}

.ant-layout-sider-trigger {
  border-right: 1px solid $adpo-gray;
}

aside {
  border-right: 1px solid $adpo-gray;
  box-sizing: border-box;
}

.ant-menu-inline {
  border-right: none;
}

.ant-menu-vertical {
  border-right: 1px solid $adpo-gray;
}

.ant-layout-header {
  // explicitly set this because we rely on this value elsewhere.
  // if an ant design update would change this, our layout would break.
  height: $ant-header-height;
}

.ant-form-item {
  margin-bottom: $margin/2 !important;
}
.ant-tabs-bar {
  margin: 0 0 $margin 0;
}

.ant-tabs .ant-tabs-left-content {
  padding-left: $margin;
}
