.ant-table-row.available-slots {
  cursor: pointer;
  line-height: 12px;
  user-select: none;
  padding: 0px;

  &.blocked {
    > td {
      color: red;
      background-color: rgba(255, 112, 119, 0.05);
    }
  }

  &.warning {
    > td {
      color: #ff9b00;
      cursor: not-allowed;
    }
  }

  &.selected {
    > td {
      //color: #00a3ff;
      background-color: #ffffe3;
    }
  }

  &.disabled {
    > td {
      color: #afacac;
      background-color: rgba(171, 168, 168, 0.09);
      cursor: not-allowed;
    }
  }

  &.selected.disabled, &.selected.blocked, &.selected.warning {
    > td {
      background-color: rgba(255, 0, 0, 0.35);
      color: $adpo-white;
    }
  }

  &.selected.disabled:hover, &.selected.blocked:hover, select.warning:hover {
    > td {
      background-color: rgba(255, 0, 0, 0.35);
      color: $adpo-white;
    }
  }
}
