$EVENT_HEIGHT: 15px;

.fc-timeline-event, .fc-timeline-event-blocked, .fc-timeline-event-external, .fc-timeline-event-internal {
  padding: 0;
  border: none;
  background: $adpo-green;
  height: 15px;

  .fc-timeline-event-arrival-block, .fc-timeline-event-departure-block {
    background: $adpo-white;
    opacity: 0.6;
  }

  .fc-timeline-event-operation-block {
    height: 15px;
  }

  .fc-timeline-event-maintenance {
    background: $adpo-yellow;
  }
}

.fc-timeline-event-external {
  background: $adpo-green;
}

.fc-timeline-event-internal {
  background: $adpo-blue;
}

.fc-timeline-event-blocked {
  background: $adpo-red;
}

.fc-timeline-event-active {
  box-shadow: 0 0 4px 1px $adpo-light-yellow;
  z-index: 999;
}

.fc-header-toolbar {
  display: none;
}

.fc-timeline-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-flow: row nowrap;

  .fc-timeline-header-column {
    width: 33.333%
  }

  .fc-timeline-header-column-middle {
    text-align: center;
  }

  .fc-timeline-header-column-right {
    text-align: right;
  }
}

.fc-event-main {
  height: $EVENT_HEIGHT;
}

.fc-timeline-event-arrival-block {
  height: $EVENT_HEIGHT;
  left: 0;
  position: absolute;
  z-index: 999;
}

.fc-timeline-event-departure-block {
  right: 0;
  height: $EVENT_HEIGHT;
  position: absolute;
  z-index: 999;
}
